import styled from 'styled-components';

const NewsletterSectionWrapper = styled.section`
  padding: 80px 0;
  overflow: hidden;
  @media (max-width: 990px) {
    padding: 40px 0 60px 0;
  }
  .countBox{
  width: 15%;
  float: left;
  text-align: center;
  margin: 5%;
  padding: 5.5% 5%;
  border: solid #000;
  border-radius: 100px;
  font-size: 18px;
  color: #fff;
  background: #0000007d;
}
`;

const NewsletterForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 488px;
  margin: 0 auto;
  @media (max-width: 575px) {
    flex-direction: column;
    max-width: 100%;
  }
  #countdownSection {
  position: relative;
}

.countdownSection{background: #10ac84}
.countdownTitle{
    text-align: center;
    font-size: 40px;
    color: #0f2137;
    margin-bottom: 20px;
    margin-top: 0;
    font-weight: 300;
    line-height: 1.5;
    -webkit-letter-spacing: -0.025em;
    -moz-letter-spacing: -0.025em;
    -ms-letter-spacing: -0.025em;
    letter-spacing: -0.025em;
}
.countBox{
  width: 25%;
  float: left;
}
.newsletterForm {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

  .reusecore__input {
    flex: 1;
    margin-right: 20px;
    @media (max-width: 575px) {
      margin: 0 0 20px 0;
      width: 100%;
    }
    .field-wrapper {
      input {
        min-height: 45px;
      }
    }
    &.is-material {
      label {
        font-size: 14px;
        top: 14px;
        font-weight: 500;
        color: rgba(51, 61, 72, 0.4);
      }
      &.is-focus {
        label {
          top: -12px;
        }
      }
    }
  }
  .countbox{
  width: 25%;
  float: left;
}
  .reusecore__button {
    flex-shrink: 0;
    transition: all 0.3s ease;
    @media (max-width: 575px) {
      width: 100%;
    }
    &:hover {
      box-shadow: 0px 9px 20px -5px rgba(16, 172, 132, 0.57);
    }
  }
`;

export { NewsletterForm };

export default NewsletterSectionWrapper;
