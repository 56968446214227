import React, { useState, useEffect } from 'react';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import NewsletterSectionWrapper from './countdownSection.style';


const Countdown = ({ targetDate, sectionHeader, sectionTitle }) => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  
  useEffect(() => {
    const countdownDate = new Date(targetDate).getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });

      if (distance < 0) {
        clearInterval(interval);
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDate]);

  return (
    <NewsletterSectionWrapper id="countdownSection" className="countdownSection">
        <Container>
        <Box {...sectionHeader}>
          <Heading className="countdownTitle" content="Application Deadline" {...sectionTitle} />
        </Box>
          <Box className="countBox">{countdown.days}<br/>days</Box>
          <Box className="countBox">{countdown.hours}<br/>hours</Box>
          <Box className="countBox">&nbsp;{countdown.minutes}<br/>minutes&nbsp;</Box>
          <Box className="countBox">&nbsp;{countdown.seconds}<br/>seconds&nbsp;</Box>
          </Container>
          </NewsletterSectionWrapper>
  );
};

export default Countdown;
