import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'common/components/GatsbyImage';
import Fade from 'react-reveal/Fade';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Card from 'common/components/Card';
import Button from 'common/components/Button';
import FeatureBlock from 'common/components/FeatureBlock';
import AboutUsSectionWrapper from './aboutUsSection.style';

const AboutUsSection = ({
  row,
  col,
  title,
  description,
  textArea,
  featureTitle,
  btnStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "image/agency/group/Rowan-01.webp" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image2: file(
        relativePath: { eq: "image/agency/group/WG-Gives.webp" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image3: file(
        relativePath: { eq: "image/agency/group/seal.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      agencyJson {
        aboutus {
          id
          title
          icon
        }
      }
    }
  `);

  return (
    <AboutUsSectionWrapper id="AboutUsSection">
      <Box className="row" {...row}>
        <Box className="col" {...col}>
          <Card className="group-gallery">
            <Box className="col1">
              <Fade top delay={30}>
                <GatsbyImage
                  src={
                    (Data.image1 !== null) | undefined
                      ? Data.image1.childImageSharp.gatsbyImageData
                      : {}
                  }
                  objectFit="contain"
                  alt="Feature Image 1"
                />
              </Fade>
              <Fade left delay={60}>
                <GatsbyImage
                  src={
                    (Data.image3 !== null) | undefined
                      ? Data.image3.childImageSharp.gatsbyImageData
                      : {}
                  }
                  objectFit="contain"
                  alt="Feature Image 3"
                />
              </Fade>
            </Box>
            <Box className="col2">
              <Fade bottom delay={90}>
                <GatsbyImage
                  src={
                    (Data.image2 !== null) | undefined
                      ? Data.image2.childImageSharp.gatsbyImageData
                      : {}
                  }
                  objectFit="contain"
                  alt="Feature Image 2"
                />
              </Fade>
            </Box>
          </Card>
        </Box>
        <Box className="col" {...col}>
          <Box {...textArea}>
            <FeatureBlock
              title={
                <Heading
                  content="How To Apply"
                  {...title}
                />
              }
              description={
                <Text
                  content="Required Documents Checklist"
                  {...description}
                />
              }
            />
          </Box>
          <Box>
            <ul>
                <li className="applyList"><input type="checkbox" /><span>  An official transcript showing you are a current high school senior at one of the Rowan County high schools;</span></li>
                <li className="applyList"><input type="checkbox" /><span>  Proof of acceptance and a declared intention to attend a community college or four-year college in the summer or fall of 2024;</span></li>
                <li className="applyList"><input type="checkbox" /><span>  Two (2) non-family (minimum age: 22) reference letters with contact information for each reference;</span></li>
                <li className="applyList"><input type="checkbox" />
                  <span>  A completed application and an essay (750 words or less) explaining how you were able to achieve excellence in academics and/or leadership despite some personal adversity 
                (for example: personal/family loss, illness or injury) you have encountered and had to overcome during your school years, how you learned from it, and how it caused you to grow as an individual.</span>
                </li>
                <li className="applyList"><input type="checkbox"/><span>  Turn in all required materials and essay to your High School Counselor. 
                    <ul className="counsel">
                      <li>Carson High School - Ashlinn Trexler - <a href="mailto:trexlerah@rss.k12.nc.us">trexlerah@rss.k12.nc.us</a></li>
                      <li>Salisbury High School - Kendra Glover - <a href="mailto:gloverke@rss.k12.nc.us">gloverke@rss.k12.nc.us</a></li>
                      <li>North Rowan High School - Angela Keaton - <a href="mailto:keatonad@rss.k12.nc.us">keatonad@rss.k12.nc.us</a></li>
                      <li>East Rowan High School - Jacqueline Taylor - <a href="mailto:taylorjh@rss.k12.nc.us">taylorjh@rss.k12.nc.us</a></li>
                      <li>South Rowan High School - Mary Geyer - <a href="mailto:Geyerme@rss.k12.nc.us">Geyerme@rss.k12.nc.us</a> or Vinny Connolly <a href="mailto:connollyvp@rss.k12.nc.us">connollyvp@rss.k12.nc.us</a></li>
                      <li>West Rowan High School - Krysta Gary - <a href="mailto:garyky@rss.k12.nc.us">garyky@rss.k12.nc.us</a></li>
                      <li>Rowan Cabarrus Early College - Pam Ratliff - <a href="mailto:ratlifpj@rss.k12.nc.us">ratlifpj@rss.k12.nc.us</a></li>
                    </ul>
                    </span>
                </li>
            </ul>
          </Box>
        </Box>
      </Box>
    </AboutUsSectionWrapper>
  );
};

AboutUsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

AboutUsSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // About us section col default style
  col: {
    width: [1, '100%', '50%'],
  },
  // About us section text area default style
  textArea: {
    maxWidth: '490px',
    pl: '40px',
  },
  // About us section title default style
  title: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '30px',
  },
  // About us section description default style
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },

  // feature title default style
  featureTitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export default AboutUsSection;
