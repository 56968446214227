import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import FeatureBlock from 'common/components/FeatureBlock';
import Container from 'common/components/UI/Container';
import WorkHistoryWrapper, { CounterUpArea } from './workHistory.style';

const WorkHistory = ({ row, col, title, date, dateText, date1, dateText1, date2, dateText2, description, btnStyle }) => {
  return (
    <WorkHistoryWrapper id="workHistorySection">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={
                <Heading
                  content="Download the Application "
                  {...title}
                />
              }
              description={
                <Text
                  content="Once you have completed the application you will need to submit it to your school’s counselor along with the required documents listed above."
                  {...description}
                />
              }
              button={<Button href="https://wallacegraham.com/graphics/Scholarship/wg_scholarship_app_23-24.pdf" title="DOWNLOAD THE APPLICATION" className="btnHover" {...btnStyle} />}
            />
          </Box>
          <Box className="col" {...col}>
            <FeatureBlock
                title={
                  <Heading
                    content="Important Dates"
                    {...title}
                  />
                }
                date={
                  <Text className="datetext"
                    content="February 2, 2024"
                    {...date}
                  />
                }
                dateText={
                  <Text className="datetext"
                  content="Deadline to submit all application materials"
                  {...dateText}
                  />
                }

                date1={
                  <Text className="datetext"
                    content="March 29, 2024"
                    {...date}
                  />
                }
                dateText1={
                  <Text className="datetext"
                  content="W&G announces the winners from each school"
                  {...dateText}
                  />
                }
                date2={
                  <Text className="datetext"
                    content="Spring 2024"
                    {...date}
                  />
                }
                dateText2={
                  <Text className="datetext"
                  content="W&G will present each scholarship at the respective school’s scholarship/award’s program"
                  {...dateText}
                  />
                }
                />
          </Box>
        </Box>
      </Container>
    </WorkHistoryWrapper>
  );
};

// WorkHistory style props
WorkHistory.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  cardStyle: PropTypes.object,
};

// WorkHistory default style
WorkHistory.defaultProps = {
  // WorkHistory section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // WorkHistory section col default style
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center',
  },
  // Card default style
  cardStyle: {
    p: ['20px 20px', '30px 20px', '30px 20px', '53px 40px'],
    borderRadius: '10px',
    boxShadow: '0px 8px 20px 0px rgba(16, 66, 97, 0.07)',
  },
  // WorkHistory section title default style
  title: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '20px',
  },
  // WorkHistory section description default style
  description: {
    fontSize: '18px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export default WorkHistory;
